
@charset "utf-8";


// If need, change your variables before importing Bulma
// $title-color: #ff0000;

@import "~bulma/bulma.sass";

textarea.bullets{

    height: 200px;
    white-space: pre-line;
    text-indent: 0px;
    overflow: hidden;
    border: none;
    resize: none;
    /*word-break: break-all;*/
}
.border{
    width:fit-content;
    border: solid black 1.1px;
}
.bulletContainer{
    margin:10px 10px 0px 0px;
}
.bullets{
    //font-family: "AdobeTimes";
    font-family: 'Times New Roman'; 
    font-size: 12pt;
    display:inline;
    font-kerning:none;
    text-rendering: geometricPrecision;
}
input.htCheckboxRendererInput{
    margin: 2px auto!important;
    display:block!important;
}
#thesaurus{
    padding: 10px;
    margin:10px 10px 0px 0px;
    width: unset;
}
#thesaurus > ul {
    columns:10;
    list-style-type:circle;
    margin: 5px auto;
}

#thesaurus > ul > li {
    margin:auto 5px;
}


#abbrTable{
    margin:10px 10px 0px 0px;
}
#bulletIOWrapper{
    min-height:10px;
}
.logo{
    color: #0F0F0F;
}
h1{
    font-family:monospace;
}
h2{
    font-family: monospace;
}

#topStuff{
    /*position: fixed;
    top: 0;
    width: 100%;
    margin-right:10px;*/
    background-color: white;
}

.toolbox{
    display:inherit;
}

.toolbox button{
    margin:5px;
}

#footer{
    text-align:center;
    font-size:12px;
    color:grey;
}
#footer div{
    display:block
}